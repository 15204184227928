import { IRequestAction } from './RequestOptions';

export const requestInit = (): IRequestAction => ({
  type: 'REQUEST_INIT',
  payload: [],
});

export const requestSuccess = (response: any): IRequestAction => ({
  type: 'REQUEST_SUCCESS',
  payload: response,
});

export const requestFailed = (response: any): IRequestAction => ({
  type: 'REQUEST_FAILED',
  payload: response,
});

export const requestFailedReset = (): IRequestAction => ({
  type: 'REQUEST_FAILED_RESET ',
});

export const requestStop = (): IRequestAction => ({
  type: 'REQUEST_STOP',
});
