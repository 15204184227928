import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useCheckAuth } from '../hooks/useCheckAuth';
import { useUser } from '../hooks/useUser';
import { RouteAppProps } from '../interfaces/Default';
import NotFound from '../screens/NotFound';
import { Loader } from './@shared/UI/Loader';

export function AppRoute({
  isCheckpoint,
  isPublic,
  roles,
  ...props
}: RouteAppProps) {
  const { user } = useUser();

  const [isAllowed, setIsAllowed] = useState<boolean>(true);
  const { isFetched, isFetching, isAuthenticated } = useCheckAuth();
  const { push } = useHistory();

  const homePage = '/';
  const authPage = '/auth/sign_in';
  const checkpointPage = '/auth/checkpoint';

  useEffect(() => {
    if (isFetched) {
      const isAuth = isAuthenticated();

      if (!isAuth && !isPublic) return push(authPage);

      if (isAuth && isPublic) return push(homePage);

      if (isAuth) {
        if (user !== null) {
          if (!user.isPasswordChanged) return push(checkpointPage);
          if (user.isPasswordChanged && isCheckpoint) return push(homePage);
        }
      }
    }
  }, [isAuthenticated, isCheckpoint, isFetched, isPublic, push, user]);

  useEffect(() => {
    if (isAuthenticated() && user === null && !isFetching) {
      return push(authPage);
    }

    if (isAuthenticated() && user !== null) {
      if (typeof roles !== 'undefined') {
        const allow = roles.find((role) => user.role === role);
        if (!allow) return setIsAllowed(false);
      }

      setIsAllowed(true);
    }
  }, [isAuthenticated, isFetching, push, roles, user]);

  return isFetching && !isFetched ? (
    <Loader hideFooter fullHeight />
  ) : !isAllowed ? (
    <NotFound />
  ) : (
    <Route {...props} />
  );
}
