import { lazy } from 'react';
import { RouteListProps } from '../../interfaces/Default';

const IndexScreen = lazy(() => import('../../screens/IndexScreen'));
const SettingScreen = lazy(() => import('../../screens/SettingScreen'));
const NotFound = lazy(() => import('../../screens/NotFound'));

const SignInScreen = lazy(() => import('../../screens/Auth/SignInScreen'));
const CheckpointScreen = lazy(
  () => import('../../screens/Auth/CheckpointScreen'),
);
const RecoverScreen = lazy(() => import('../../screens/Auth/RecoverScreen'));

const DesignationScreen = lazy(
  () => import('../../screens/Admin/DesignationScreen'),
);
const DeductionScreen = lazy(
  () => import('../../screens/Admin/DeductionScreen'),
);
const SourceScreen = lazy(() => import('../../screens/Admin/SourceScreen'));
const SignatoryScreen = lazy(
  () => import('../../screens/Admin/SignatoryScreen'),
);

const EmployeeScreen = lazy(
  () => import('../../screens/Admin/Employee/EmployeeScreen'),
);
const EmployeeCreateScreen = lazy(
  () => import('../../screens/Admin/Employee/EmployeeCreateScreen'),
);
const EmployeeLedgerScreen = lazy(
  () => import('../../screens/Admin/Employee/EmployeeLedgerScreen'),
);

const PayrollScreen = lazy(
  () => import('../../screens/Admin/Payroll/PayrollScreen'),
);
const PayrollCreateScreen = lazy(
  () => import('../../screens/Admin/Payroll/PayrollCreateScreen'),
);
const PayrollViewScreen = lazy(
  () => import('../../screens/Admin/Payroll/PayrollViewScreen'),
);

export const routeList: RouteListProps[] = [
  { path: '/', Component: IndexScreen, exact: true },
  {
    path: '/auth',
    children: [
      {
        path: '/auth/sign_in',
        Component: SignInScreen,
        exact: true,
        isPublic: true,
      },
      {
        path: '/auth/checkpoint',

        Component: CheckpointScreen,
        exact: true,
        isCheckpoint: true,
      },
      {
        path: '/auth/recover',
        Component: RecoverScreen,
        exact: true,
        isPublic: true,
      },
    ],
  },
  {
    path: '/admin',
    children: [
      {
        path: '/admin/designations',
        Component: DesignationScreen,
        exact: true,
        roles: ['ADMINISTRATOR'],
      },
      {
        path: '/admin/deductions',
        Component: DeductionScreen,
        exact: true,
        roles: ['ADMINISTRATOR'],
      },
      {
        path: '/admin/sources',
        Component: SourceScreen,
        exact: true,
        roles: ['ADMINISTRATOR'],
      },
      {
        path: '/admin/signatories',
        Component: SignatoryScreen,
        exact: true,
        roles: ['ADMINISTRATOR', 'MANAGER'],
      },
      {
        path: '/admin/employees',
        children: [
          {
            path: '/admin/employees',
            Component: EmployeeScreen,
            exact: true,
            roles: ['ADMINISTRATOR', 'MANAGER'],
          },
          {
            path: '/admin/employees/create',
            Component: EmployeeCreateScreen,
            exact: true,
            roles: ['ADMINISTRATOR'],
          },
          {
            path: '/admin/employees/:id',
            Component: EmployeeLedgerScreen,
            exact: true,
            roles: ['ADMINISTRATOR', 'MANAGER'],
          },
        ],
      },
      {
        path: '/admin/payrolls',
        children: [
          {
            path: '/admin/payrolls',
            Component: PayrollScreen,
            exact: true,
            roles: ['ADMINISTRATOR', 'MANAGER'],
          },
          {
            path: '/admin/payrolls/create',
            Component: PayrollCreateScreen,
            exact: true,
            roles: ['ADMINISTRATOR', 'MANAGER'],
          },
          {
            path: '/admin/payrolls/:id',
            Component: PayrollViewScreen,
            exact: true,
            roles: ['ADMINISTRATOR', 'MANAGER'],
          },
        ],
      },
    ],
  },
  { path: '/settings', Component: SettingScreen, exact: true },
  { path: 'NOT_FOUND', Component: NotFound },
];
