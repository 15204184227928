import React, { Suspense } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from '../config/query/queryClient';
import { Loader } from './@shared/UI/Loader';
import { AppRoutes } from './AppRoutes';

export function App() {
  return (
    <Suspense fallback={<Loader hideFooter fullHeight />}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppRoutes />
      </QueryClientProvider>
    </Suspense>
  );
}
