import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routeList } from '../config/routes/routeList';
import { RouteListChildrenProps } from '../interfaces/Default';
import { AppRoute } from './AppRoute';

export function AppRoutes() {
  function renderRoute(list: RouteListChildrenProps[]): any {
    return list.map(({ path, Component, children, ...props }, index) => {
      if (typeof Component === 'undefined') {
        if (typeof children !== 'undefined') {
          return renderRoute(children);
        }

        return null;
      }

      if (path === 'NOT_FOUND') {
        return <Route key={index} render={() => <Component />} />;
      }

      return (
        <AppRoute
          key={index}
          path={path}
          render={() => <Component />}
          {...props}
        />
      );
    });
  }

  return <Switch>{renderRoute(routeList)}</Switch>;
}
