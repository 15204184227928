import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { http } from '../api';
import { LOCAL_STORAGE_USER } from '../config/constants';
import { userState } from '../state/User';

export function useCheckAuth() {
  const setUser = useSetRecoilState(userState);

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const isAuthenticated = useCallback(() => {
    const user = localStorage.getItem(LOCAL_STORAGE_USER);
    return !!user;
  }, []);

  const checkUser = useCallback(async () => {
    try {
      const response = await http('/auth/me', {
        method: 'get',
        withCredentials: true,
      });

      if (response.status === 200) {
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(response.data));
        return setUser(response.data);
      }

      if (response.status === 204) {
        localStorage.removeItem(LOCAL_STORAGE_USER);
      }

      setUser(null);
    } catch (error) {
      setUser(null);
    } finally {
      setIsFetching(false);
      setIsFetched(true);
    }
  }, [setUser]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return { isFetching, isFetched, isAuthenticated };
}
