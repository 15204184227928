import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { DefaultLayout } from '../../Layout/DefaultLayout';
import { LoaderProps } from '../../../interfaces/Layout';

export function Loader(props: LoaderProps) {
  return (
    <DefaultLayout {...props} color="LIGHT">
      <Spin indicator={<LoadingOutlined spin />} />
    </DefaultLayout>
  );
}
