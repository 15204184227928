import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { DefaultLayoutProps } from '../../interfaces/Layout';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Footer } from '../@shared/Layout/Footer';

export function DefaultLayout({
  children,
  pageTitle,
  hideFooter,
  color,
  ...props
}: DefaultLayoutProps) {
  const { title } = usePageTitle(pageTitle);

  return (
    <Wrapper {...props}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Content>
        <>{children}</>
        {!hideFooter && <Footer color={color} />}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled('section')(({ fullHeight }: any) => ({
  minHeight: typeof fullHeight !== 'undefined' ? '100vh' : '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Content = styled('main')({
  display: 'flex',
  flexDirection: 'column',
});
