import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { User } from '../interfaces/models/User';
import { userState } from '../state/User';

export function useUser() {
  const user = useRecoilValue<User>(userState);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const [isPermanent, setIsPermanent] = useState<boolean>(true);

  useEffect(() => {
    if (user !== null) {
      if (user.role === 'ADMINISTRATOR') return setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (user !== null) {
      if (user.role === 'MANAGER') return setIsManager(true);
    }
  }, [user]);

  useEffect(() => {
    if (user !== null) {
      if (user.role === 'EMPLOYEE') return setIsEmployee(true);
    }
  }, [user]);

  useEffect(() => {
    if (user !== null) {
      if (!user.isPermanent) return setIsPermanent(false);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      setIsAdmin(false);
      setIsManager(false);
      setIsEmployee(false);
      setIsPermanent(false);
    };
  }, []);

  return {
    user,
    isAdmin,
    isManager,
    isEmployee,
    isPermanent,
  };
}
