import { IRequestAction, IRequestState } from '../interfaces/RequestOptions';

export const requestInitialState: IRequestState = {
  isFetching: false,
  isFetched: false,
  isFailed: false,
  data: [],
};

export const requestReducer = (
  state: IRequestState,
  action: IRequestAction,
): IRequestState => {
  const prevState = Object.assign({}, state);

  switch (action.type) {
    case 'REQUEST_INIT':
      return {
        ...prevState,
        isFetching: true,
        isFailed: false,
        isFetched: false,
      };
    case 'REQUEST_SUCCESS':
      return {
        ...prevState,
        isFetching: false,
        isFetched: true,
        isFailed: false,
        data: action.payload,
      };
    case 'REQUEST_FAILED':
      return {
        ...prevState,
        isFetching: false,
        isFetched: false,
        isFailed: true,
        data: action.payload,
      };
    case 'REQUEST_FAILED_RESET ':
      return {
        ...prevState,
        isFailed: false,
      };
    case 'REQUEST_STOP':
      return {
        ...prevState,
        isFetching: false,
      };
    default:
      return state;
  }
};
