export const APP_NAME = 'Payroll Management and Monitoring System';
export const APP_OFFICE =
  'Department of Science and Technology - Regional Office I';

export const APP_OFFICE_NAME = 'Department of Science and Technology';
export const APP_REGIONAL_OFFICE = 'Regional Office I';
export const APP_EMAL = 'mail@region1.dost.gov.ph';
export const APP_CONTACT_NUMBER = '(072) 888-3399';

export const LOCAL_STORAGE_USER = 'PMMS_USER';
export const LOCAL_STORAGE_SHOW_ALERT = 'PMMS_ALERT_HIDE';

export const PAGE_LIMIT = 10;
export const DEBOUNCE_RATE = 500;
export const CURRENT_YEAR = new Date().getFullYear();

export const JO_WORKING_HOURS = 8;
export const JO_MINUTES_HOUR = 60;
