import { useCallback, useReducer } from 'react';
import {
  requestFailed,
  requestFailedReset,
  requestInit,
  requestStop,
  requestSuccess,
} from '../interfaces/RequestAction';
import {
  requestInitialState,
  requestReducer,
} from '../reducers/RequestReducer';
import { http } from '../service/apiRequest';
import { message } from 'antd';

export const useApiRequest = () => {
  const [state, dispatch] = useReducer(requestReducer, requestInitialState);

  const query = useCallback(
    async (
      path: string,
      method: 'get' | 'post' | 'patch' | 'put' | 'delete',
      values?: any,
    ) => {
      dispatch(requestInit());

      try {
        const response = await http({
          url: path,
          data: values,
          withCredentials: true,
          method,
        });

        dispatch(requestSuccess(response.data));

        return response;
      } catch (error) {
        if (typeof error.response !== 'undefined') {
          const errorPayload = error.response.data;
          return dispatch(requestFailed(errorPayload));
        }

        dispatch(requestStop());
        message.error('Internal Server Error');
      }
    },
    [],
  );

  const reset = () => dispatch(requestFailedReset());

  return { state, query, reset };
};
