import { useEffect, useState } from 'react';
import { APP_NAME, APP_OFFICE } from '../config/constants';

export function usePageTitle(name?: string) {
  const [title, setTitle] = useState(`${APP_NAME} - ${APP_OFFICE}`);

  useEffect(() => {
    if (typeof name !== 'undefined')
      return setTitle(`${name} - ${APP_NAME} | ${APP_OFFICE}`);
  }, [name]);

  return { title };
}
