import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LogoImage from '../assets/logo.png';
import { DefaultLayout } from '../components/Layout/DefaultLayout';
import { Title, Text } from '../components/Shared/Data/TypographyBase';

export default function NotFound() {
  const { push } = useHistory();

  function handleRedirect() {
    push('/');
  }

  return (
    <DefaultLayout pageTitle="Not Found" color="LIGHT">
      <Wrapper>
        <Logo src={LogoImage} alt="DOST Logo" />

        <Title level={4}>Uh Oh! Page not found</Title>
        <Text>Looks like this page is missing, go back to the home page.</Text>

        <Button type="primary" onClick={handleRedirect}>
          Go Back to Home
        </Button>
      </Wrapper>
    </DefaultLayout>
  );
}

const Wrapper = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '100vh',

  '& button': {
    marginTop: '1rem',
  },
});

const Logo = styled('img')({
  width: '85px',
  height: '85px',
  marginBottom: '1rem',
});
