import React from 'react';
import styled from 'styled-components';

import { Footer as FooterWrapper } from '.';

interface Props {
  color: string;
}

export function Footer({ color }: Props) {
  return (
    <Wrapper color={color}>
      <Caption>
        Developed by ITSM Unit &raquo; v{process.env.REACT_APP_VERSION}
      </Caption>
    </Wrapper>
  );
}

const Wrapper = styled(FooterWrapper)(({ color }: any) => ({
  color: color === 'DARK' ? '#fff' : 'grey',
  backgroundColor: 'transparent',
}));

const Caption = styled('p')({
  textAlign: 'center',
  margin: 0,
});
