type ViewPort = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface ThemeOptions {
  utils: {
    padding: string;
    marginBottom: string;
    maxWidth: string;
    gutters(): any;
  };
  colors: {
    primary: string;
    white: string;
    black: string;
    grey: string;
    greyLight: string;
    border: string;
  };
  breakpoints: {
    down(breakpoint: ViewPort): string;
    up(breakpoint: ViewPort): string;
  };
}

const breakpoints = {
  xs: '320px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1920px',
};

export const theme: ThemeOptions = {
  utils: {
    padding: '1.5rem',
    marginBottom: '1rem',
    maxWidth: '1024px',
    gutters: () => {
      return {
        maxWidth: theme.utils.maxWidth,
        margin: '0 auto',
        padding: '0.5rem 1rem',
        marginTop: '1rem',

        [`@media (min-width: ${breakpoints.md})`]: {
          marginTop: '2rem',
        },
      };
    },
  },
  colors: {
    primary: '#1764C0',
    white: '#FFFFFF',
    black: '#000000',
    grey: '#757575',
    greyLight: '#C1C1C1',
    border: '#F0F0F0',
  },
  breakpoints: {
    down: (breakpoint: ViewPort) => {
      let width = breakpoints.xl;

      if (breakpoint === 'lg') width = breakpoints.lg;
      if (breakpoint === 'md') width = breakpoints.md;
      if (breakpoint === 'sm') width = breakpoints.sm;
      if (breakpoint === 'xs') width = breakpoints.xs;

      return `@media (max-width: ${width})`;
    },
    up: (breakpoint: ViewPort) => {
      let width = breakpoints.xl;

      if (breakpoint === 'lg') width = breakpoints.lg;
      if (breakpoint === 'md') width = breakpoints.md;
      if (breakpoint === 'sm') width = breakpoints.sm;
      if (breakpoint === 'xs') width = breakpoints.xs;

      return `@media (min-width: ${width})`;
    },
  },
};
